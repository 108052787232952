import {
    TResponseApiStudioSubscriptionCustomerCreate,
    TResponseApiStudioSubscriptionTrialCreate,
} from "@magoot-sdk/api/studio/studioSubscription/response";
import { TUser } from "@magoot-sdk/redux-modules/reducers/reducerUsers/declarations";
import { Actions, TError } from "@magoot-sdk/redux-modules/utils/Actions";

import {
    RetrieveUpcomingInvoiceStoreValue,
    TBillingInformation,
    TCurrentSubscription,
    TPlans,
    TStripeListPaymentMethods,
} from "../../../reducers/reducerSubscription/declarations";
import {
    TActionStudioSubscriptionCalcolaImportiFailure,
    TActionStudioSubscriptionCalcolaImportiRequest,
    TActionStudioSubscriptionCalcolaImportiSuccess,
    TActionStudioSubscriptionClearDeliteUsers,
    TActionStudioSubscriptionCustomerCreateFailure,
    TActionStudioSubscriptionCustomerCreateRequest,
    TActionStudioSubscriptionCustomerCreateSuccess,
    TActionStudioSubscriptionCustomerFailure,
    TActionStudioSubscriptionCustomerRequest,
    TActionStudioSubscriptionCustomerSuccess,
    TActionStudioSubscriptionDeliteAllUsers,
    TActionStudioSubscriptionDeliteUsers,
    TActionStudioSubscriptionFailure,
    TActionStudioSubscriptionRequest,
    TActionStudioSubscriptionRetrieveUpcomingInvoiceFailure,
    TActionStudioSubscriptionRetrieveUpcomingInvoiceRequest,
    TActionStudioSubscriptionRetrieveUpcomingInvoiceSuccess,
    TActionStudioSubscriptionSuccess,
    TActionStudioSubscriptionTrialCreateFailure,
    TActionStudioSubscriptionTrialCreateRequest,
    TActionStudioSubscriptionTrialCreateSuccess,
    TActionSubscriptionBillingInformationError,
    TActionSubscriptionBillingInformationRequest,
    TActionSubscriptionBillingInformationSuccess,
    TActionSubscriptionListPaymentMethodError,
    TActionSubscriptionListPaymentMethodRequest,
    TActionSubscriptionListPaymentMethodSuccess,
    TActionSubscriptionPlansError,
    TActionSubscriptionPlansRequest,
    TActionSubscriptionPlansSelected,
    TActionSubscriptionPlansSuccess,
} from "./declarations";

export const actionStudioSubscriptionRequest = (
    idStudio: number
): TActionStudioSubscriptionRequest => ({
    type: Actions.studioSubscriptionRequest,
    payload: { idStudio },
});

export const actionStudioSubscriptionSuccess = (payload: {
    idStudio: number;
    subscription: TCurrentSubscription;
}): TActionStudioSubscriptionSuccess => ({
    type: Actions.studioSubscriptionSuccess,
    payload,
});

export const actionStudioSubscriptionFailure = (
    payload: Error
): TActionStudioSubscriptionFailure => ({
    type: Actions.studioSubscriptionFailure,
    payload,
});

// STUDIO SUBSCRIPTION GET CUSTOMER
export const actionStudioSubscriptionCustomerRequest = (payload: {
    idStudio: number;
}): TActionStudioSubscriptionCustomerRequest => ({
    type: Actions.studioSubscriptionCustomerRequest,
    payload,
});

export const actionStudioSubscriptionCustomerSuccess = (payload: {
    idStudio: number;
    customerId: string;
}): TActionStudioSubscriptionCustomerSuccess => ({
    type: Actions.studioSubscriptionCustomerSuccess,
    payload,
});

export const actionStudioSubscriptionCustomerFailure = (
    payload: Error
): TActionStudioSubscriptionCustomerFailure => ({
    type: Actions.studioSubscriptionCustomerFailure,
    payload,
});

// STUDIO SUBSCRIPTION BILLING INFORMATION
export const actionSubscriptionBillingInformationRequest = (payload: {
    idStudio: number;
}): TActionSubscriptionBillingInformationRequest => ({
    type: Actions.subscriptionBillingInformationRequest,
    payload,
});

export const actionSubscriptionBillingInformationSuccess = (
    payload: TBillingInformation
): TActionSubscriptionBillingInformationSuccess => ({
    type: Actions.subscriptionBillingInformationSuccess,
    payload,
});

export const actionSubscriptionBillingInformationError = (
    payload: TError
): TActionSubscriptionBillingInformationError => ({
    type: Actions.subscriptionBillingInformationError,
    payload,
});

// STUDIO SUBSCRIPTION PLANS
export const actionSubscriptionPlansRequest = (payload: {
    idStudio: number;
    currentSubscription: TCurrentSubscription;
    quantityPlan1?: number | boolean;
    quantityPlan2?: number | boolean;
}): TActionSubscriptionPlansRequest => ({
    type: Actions.subscriptionPlansRequest,
    payload,
});

export const actionSubscriptionPlansSuccess = (payload: {
    idStudio: number;
    result: TPlans[];
}): TActionSubscriptionPlansSuccess => ({
    type: Actions.subscriptionPlansSuccess,
    payload,
});

export const actionSubscriptionPlansError = (payload: TError): TActionSubscriptionPlansError => ({
    type: Actions.subscriptionPlansError,
    payload,
});

export const actionSubscriptionPlansSelected = (payload: {
    idStudio: number;
    idPlan: string;
}): TActionSubscriptionPlansSelected => ({
    type: Actions.subscriptionPlansSelected,
    payload,
});

// STUDIO SUBSCRIPTION LIST PAYMENT METHOD
export const actionSubscriptionListPaymentMethodRequest = (payload: {
    customer: string;
    idStudio: number;
}): TActionSubscriptionListPaymentMethodRequest => ({
    type: Actions.subscriptionListPaymentMethodRequest,
    payload,
});

export const actionSubscriptionListPaymentMethodSuccess = (payload: {
    idStudio: number;
    result: TStripeListPaymentMethods;
}): TActionSubscriptionListPaymentMethodSuccess => ({
    type: Actions.subscriptionListPaymentMethodSuccess,
    payload,
});

export const actionSubscriptionListPaymentMethodError = (
    payload: TError
): TActionSubscriptionListPaymentMethodError => ({
    type: Actions.subscriptionListPaymentMethodError,
    payload,
});

// STUDIO SUBSCRIPTION CUSTOMER CREATE
export const actionStudioSubscriptionCustomerCreateRequest = (payload: {
    idStudio: number;
    idUser: number;
}): TActionStudioSubscriptionCustomerCreateRequest => ({
    type: Actions.studioSubscriptionCustomerCreateRequest,
    payload,
});

export const actionStudioSubscriptionCustomerCreateSuccess = (
    payload: TResponseApiStudioSubscriptionCustomerCreate
): TActionStudioSubscriptionCustomerCreateSuccess => ({
    type: Actions.studioSubscriptionCustomerCreateSuccess,
    payload,
});

export const actionStudioSubscriptionCustomerCreateFailure = (
    payload: Error
): TActionStudioSubscriptionCustomerCreateFailure => ({
    type: Actions.studioSubscriptionCustomerCreateFailure,
    payload,
});

// STUDIO SUBSCRIPTION TRIAL CREATE
export const actionStudioSubscriptionTrialCreateRequest = (payload: {
    idCustomer: string;
    idStudio: number;
}): TActionStudioSubscriptionTrialCreateRequest => ({
    type: Actions.studioSubscriptionTrialCreateRequest,
    payload,
});

export const actionStudioSubscriptionTrialCreateSuccess = (
    payload: TResponseApiStudioSubscriptionTrialCreate
): TActionStudioSubscriptionTrialCreateSuccess => ({
    type: Actions.studioSubscriptionTrialCreateSuccess,
    payload,
});

export const actionStudioSubscriptionTrialCreateFailure = (
    payload: Error
): TActionStudioSubscriptionTrialCreateFailure => ({
    type: Actions.studioSubscriptionTrialCreateFailure,
    payload,
});

// STUDIO SUBSCRIPTION RETRIEVE UPCOMING INVOICE
export const actionStudioSubscriptionRetrieveUpcomingInvoiceRequest = (payload: {
    idStudio: number;
    customerId: string;
    subscriptionId: string;
    newPriceId: string;
    quantity: number;
    subscriptionTrialEnd?: Date | null;
}): TActionStudioSubscriptionRetrieveUpcomingInvoiceRequest => ({
    type: Actions.studioSubscriptionRetrieveUpcomingInvoiceRequest,
    payload,
});

export const actionStudioSubscriptionRetrieveUpcomingInvoiceSuccess = (payload: {
    idStudio: number;
    retrieveUpcomingInvoice: RetrieveUpcomingInvoiceStoreValue;
}): TActionStudioSubscriptionRetrieveUpcomingInvoiceSuccess => ({
    type: Actions.studioSubscriptionRetrieveUpcomingInvoiceSuccess,
    payload,
});

export const actionStudioSubscriptionRetrieveUpcomingInvoiceFailure = (
    payload: Error
): TActionStudioSubscriptionRetrieveUpcomingInvoiceFailure => ({
    type: Actions.studioSubscriptionRetrieveUpcomingInvoiceFailure,
    payload,
});

export const actionStudioSubscriptionCalcolaImportiRequest = (payload: {
    idStudio: number;
    customerId: string;
    newPriceId: string;
    quantity: number;
}): TActionStudioSubscriptionCalcolaImportiRequest => ({
    type: Actions.studioSubscriptionCalcolaImportiRequest,
    payload,
});

export const actionStudioSubscriptionCalcolaImportiSuccess = (payload: {
    idStudio: number;
    retrieveUpcomingInvoice: RetrieveUpcomingInvoiceStoreValue;
}): TActionStudioSubscriptionCalcolaImportiSuccess => ({
    type: Actions.studioSubscriptionCalcolaImportiSuccess,
    payload,
});

export const actionStudioSubscriptionCalcolaImportiFailure = (
    payload: Error
): TActionStudioSubscriptionCalcolaImportiFailure => ({
    type: Actions.studioSubscriptionCalcolaImportiFailure,
    payload,
});

// STUDIO SUBSCRIPTION DELITE USERS
export const actionStudioSubscriptionDeleteUsers = (payload: {
    idStudio: number;
    users: TUser[];
}): TActionStudioSubscriptionDeliteUsers => ({
    type: Actions.studioSubscriptionDeliteUsers,
    payload,
});

export const actionStudioSubscriptionClearDeleteUser = (payload: {
    idStudio: number;
    user: number;
}): TActionStudioSubscriptionClearDeliteUsers => ({
    type: Actions.studioSubscriptionClearDeliteUsers,
    payload,
});

export const actionStudioSubscriptionDeleteAllUser = (payload: {
    idStudio: number;
}): TActionStudioSubscriptionDeliteAllUsers => ({
    type: Actions.studioSubscriptionDeliteAllUsers,
    payload,
});
